import React from "react";
import { useState } from "react";
import { AdverseEvents } from "../../../components/MedicalRecords/AdverseEvents";
import { useAdverseEvents } from "../Hooks/useAdverseEvents";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useTranslation } from "react-i18next";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { ModalAdverseEventHistory } from "../Modals/ModalAdverseEventHistory";
import { conditions } from "../../../variables/Enums";

export const AdverseEventsListContainer = ({ mrn, medicalRecordNumber, entryId, expandedAll }) => {
    const { t } = useTranslation();
    const [adverseEvents, query, totalSize, isLoading, handleTableChange,] = useAdverseEvents(medicalRecordNumber, entryId, false);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="AdverseEvents" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handleHistory = (_itemId, _mrn, item) => {

        setModal(<ModalAdverseEventHistory
            onClose={() => { setModal(null); }}
            title={t("medicalRecords.history.history")}
            medicalRecordNumber={medicalRecordNumber || mrn}
            conceptId={item.name?.conceptId}
            condition={conditions.adverseEvent}
        />)
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.adverseEvents.title")} content="adverseEvents" expandedAll={expandedAll}>
            {modal}
            <AdverseEvents
                withCard={false}
                data={adverseEvents}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
                onHistory={handleHistory}
            />
        </ExpandableContent>
    );
}