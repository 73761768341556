import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import DateTime from 'react-datetime'
import moment from 'moment';

export const CustomDateTime = (props) => {
    const { value, defaulValue, dateFormat, onChange, onBlur, ...rest } = props;

    const [data, setData] = useState("");

    useEffect(() => {
        if (!moment.isMoment(value))
            setData(moment(value).format(dateFormat));
        else
            setData(value);
    }, [])

    const handleChange = value => {
        setData(value);

        var date = moment(value, dateFormat, true);
        if (date.isValid() && onChange)
            onChange(props.name, date);

        /*
        if (!onChange) return;

        if (value === "") {
            setData("");
            onChange(props.name, "");
        }

        var date = moment(value, dateFormat, true);
        if (date.isValid()) {
            setData(date)
            onChange(props.name, date);
        }*/
    }

    const handleBlur = () => {
        if (!onBlur)
            return;
        onBlur(props.name, true);
    }

    return (

        <DateTime
            {...rest}
            inputProps={{
                disabled: props.disabled,
                placeholder: "DD/MM/YYYY HH:mm"
            }}
            value={data}
            onChange={handleChange}
            onBlur={handleBlur}
        />

    );
}

CustomDateTime.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};
CustomDateTime.defaultProps = {
    dateFormat: "DD/MM/YYYY HH:mm",
    inputProps: { placeholder: "DD/MM/YYYY HH:mm" },
};

export const CustomTime = (props) => {
    const { value, onChange, ...rest } = props;

    const [data, setData] = useState("");
    useEffect(() => {
        if (!moment.isMoment(value))
            setData(moment(value).format("HH:mm"));
        else
            setData(value);
    }, [])

    const handleChange = value => {
        setData(value);

        var date = moment(value, "HH:mm", true);
        if (date.isValid() && onChange)
            onChange(props.name, date);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    return (
        <>
            <DateTime
                value={data}
                {...rest}
                dateFormat={false}
                minuteFormat="HH:mm"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ placeholder: "HH:mm", disabled: props.disabled }}
            />
        </>
    );
}

CustomTime.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};

export const CustomDate = (props) => {
    const { value, onChange, onBlur, dateFormat } = props;
    const [data, setData] = useState("");

    useEffect(() => {
        if (!moment.isMoment(value))
            setData(moment(value).format(dateFormat));
        else
            setData(value);
    }, [])

    const handleChange = value => {
        setData(value);

        var date = moment(value, dateFormat, true);
        if (date.isValid() && onChange)
            onChange(props.name, date);
    }

    const handleBlur = () => {
        if (onBlur)
            onBlur(props.name, true);
    };

    return (
        <DateTime
            {...props}
            inputProps={{
                placeholder: 'DD/MM/AAAA',
                disabled: props.disabled,
            }}
            value={data}
            timeFormat={false}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
}

CustomDate.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};
CustomDate.defaultProps = {
    dateFormat: "DD/MM/YYYY",
    inputProps: { placeholder: "DD/MM/YYYY" },
};
