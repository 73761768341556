import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Token } from 'react-bootstrap-typeahead';
import { useTranslation, withTranslation } from 'react-i18next';
import { AuditModal, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { SnomedPicker } from '../../../components/CustomControls/SnomedPicker';
import { DiagnosisForm } from '../../../components/MedicalRecords/Forms/DiagnosisForm';
import { ModalData } from '../../../components/Modals/ModalData';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { getRemovedElement } from '../../../components/Utils/Commons';
import { nullableDateFormatter } from '../../../components/Utils/Formatter';
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { conceptType, snomedExpressions } from '../../../variables/Enums';
import { useDiagnoses } from '../Hooks/useDiagnosis';
import Conditions from '../../../components/MedicalRecords/Conditions';
import { AuditTrail } from '../../Admin/AuditTrail';
import { useAdverseEvent } from '../Hooks/useAdverseEvents';

export const DiagnosesContainer = (props) => {
    const { medicalRecordNumber, entryId } = props;
    const [data] = useDiagnoses(entryId);
    const [diagnoses, setDiagnoses] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setDiagnoses(data.map(x => {
            return {
                ...x,
                conceptId: x.adverseEffectId,
                description: x.adverseEffect
            }
        }))
    }, [data]);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="AdverseEvents" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    if (diagnoses?.length === 0)
        return null

    return (
        <>
            {modal}
            <ExpandableContent title={t("medicalRecords.condition.diagnosis")} expandedAll={true} content="adverseEvents">
                <Conditions
                    data={diagnoses}
                    onAuditTrail={handleAuditTrail}
                />
            </ExpandableContent>
        </>
    );
}

export const DiagnosisCreateContainer = (props) => {
    const { entryStatus, entryId, onSubmit, disabled } = props;
    const [data, , , , , setReload, create, remove, update] = useDiagnoses(entryId);
    const [content, setContent] = useState(null);
    const [diagnoses, setDiagnoses] = useState([]);
    let _addedDiagnosis = null;
    let _addedDiagnoses = [];
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "conditions")
            setReload(true);

    }, [props.contentToReload]);

    useEffect(() => {
        setDiagnoses(data.map(x => {
            return {
                ...x,
                conceptId: x.adverseEffectId,
                description: x.adverseEffect
            }
        }))
    }, [data]);

    const handleSubmit = async (data) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        if (!_addedDiagnosis)
            return;

        if (reason) {
            data.auditReason = reason;
            data.auditComments = comments;
        }

        data.condition.name = {};
        Object.assign(data.condition.name, _addedDiagnosis); // Mergeo props de Picker y modal. 

        try {
            const res = await create(entryId, data);
            _addedDiagnosis.id = res.data?.id;
            // Actualizo State. 
            Object.assign(_addedDiagnosis, data.condition); // Mergeo props de Picker y modal. 
            const editedDiagnoses = _addedDiagnoses.map((condition) => condition.conceptId === _addedDiagnosis.conceptId ? _addedDiagnosis : condition);

            setDiagnoses(editedDiagnoses);
            _addedDiagnosis = null;
        }
        catch (error) {
            HandleApiError(error);
            console.log(error)
        }

        setContent(null);
        onSubmit("diagnoses", _addedDiagnoses?.length > 0); // Indico al padre sobre q contenido hacer Reload. 
    }

    const handleCancel = () => {
        const editedDiagnoses = diagnoses.filter(x => x.conceptId !== _addedDiagnosis.conceptId);
        setContent(null);
        setDiagnoses(editedDiagnoses);
    }

    const handleChangeDiagnosis = async (_name, value) => {
        _addedDiagnoses = value;

        // Determino si se agregó o eliminó un elemento (o todos)
        if (value == null || diagnoses?.length > value.length) { // Elemento/s eliminado/s
            _addedDiagnosis = null;
            const elementoEliminado = getRemovedElement(diagnoses, value);
            if (elementoEliminado && elementoEliminado.id) {
                try {
                    await remove(entryId, elementoEliminado.id);
                }
                catch (error) {
                    HandleApiError(error);
                    console.log(error)
                }
            }

            onSubmit("diagnoses", _addedDiagnoses?.length > 0); // Indico al padre sobre q contenido hacer Reload. 
            setDiagnoses(value);
        }
        else { // Elemento agregado
            _addedDiagnosis = value.at(-1); // Obtengo último diagnostico agregado, para poder modificarlo. 
            if (_addedDiagnosis) {
                setContent(
                    <ModalData
                        title={_addedDiagnosis.description}
                        isShowing={true}
                        size="medium"
                        className=""
                        hide={handleCancel}
                    >
                        <DiagnosisContainerFormik
                            onSubmit={handleSubmit}
                            onCancel={handleCancel}
                        />
                    </ModalData>
                );
            }
        }
    }

    const handleEdit = async (data) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmEdit(data, reason, comments)}
            />)
        }
        else {
            onConfirmEdit(data);
        }
    }

    const onConfirmEdit = async (data, reason, comments) => {
        try {
            if (data && data.condition) {
                let adverseEvent = data.condition;
                if (reason) {
                    adverseEvent.auditReason = reason;
                    adverseEvent.auditComments = comments;
                }
                await update(entryId, adverseEvent.id, adverseEvent);
                setContent(null);
                setReload(true);
            }
        }
        catch (error) {
            HandleApiError(error);
            console.log(error);
        }
    }

    const handleClickDiagnosis = (item) => {
        setContent(<DiagnosisModalEditContainer
            condition={item}
            onSubmit={handleEdit}
            onCancel={() => setContent(null)}
        />)
    }

    return (
        <>
            {content}
            <ExpandableContent title={t("medicalRecords.condition.diagnosis")} expandedAll={true} content="adverseEvents">
                <SnomedPicker
                    conceptType={conceptType.events}
                    id="condition"
                    name="condition"
                    disabled={disabled}
                    value={diagnoses}
                    selected={diagnoses}
                    placeholder={t("commons.search")}
                    onChange={handleChangeDiagnosis}
                    expression={snomedExpressions.Diagnostico}
                    multiple={true}
                    clearButton={false}
                    renderToken={(option, { onRemove }, index) => (
                        <Token key={index}
                            disabled={disabled}
                            onRemove={disabled ? undefined : onRemove}
                            option={option}
                        >
                            <div onClick={() => handleClickDiagnosis(option)}>
                                <div>
                                    {option.isChronic && t("medicalRecords.condition.chronic")} {option.description}
                                </div>
                                {
                                    option.intensity &&
                                    <div style={{ fontSize: 12 }}>
                                        {t("medicalRecords.condition.intensity.title")}: {option?.intensity?.title || option.intensity}
                                    </div>
                                }
                                {
                                    option.fromYear &&
                                    <div style={{ fontSize: 12 }}>
                                        {t("medicalRecords.condition.from")}: {nullableDateFormatter(option.fromDay, option.fromMonth, option.fromYear)}
                                    </div>
                                }
                                {
                                    option.toYear &&
                                    <div style={{ fontSize: 12 }}>
                                        {t("medicalRecords.condition.to")}: {nullableDateFormatter(option.toDay, option.toMonth, option.toYear)}
                                    </div>
                                }
                            </div>
                        </Token>
                    )}
                />
            </ExpandableContent>
        </>
    );
}

const DiagnosisModalEditContainer = (props) => {
    const { condition, onSubmit, onCancel } = props;
    const [data, isLoading] = useAdverseEvent(condition.entryId, condition.id);

    if (!data || isLoading)
        return null;

    return (
        <ModalData
            title={data.name?.description}
            isShowing={true}
            size="medium"
            className=""
            hide={onCancel}
        >
            <DiagnosisContainerFormik
                onSubmit={onSubmit}
                onCancel={onCancel}
                condition={data} />
        </ModalData>

    );
}

export const DiagnosisContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.condition && { condition: props.condition }),

    validationSchema: getMedicalRecordsSchema().adverseEvent,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'DiagnosisForm',

})(DiagnosisForm));

DiagnosisContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    adverseEvent: PropTypes.object.isRequired,
};